<template>
    <b-container>
        <h1>Willkommen im Haus Nina</h1>
        <b-row>
            <b-col cols="12" sm="6">
                <p>Suchen Sie für Ihren Aufenthalt in Warnemünde eine großzügige Ferienwohnung in Strandnähe? Dann sind Sie im Haus Nina goldrichtig. Das historische Gebäude aus der Blütezeit des Seebads wurde 2009/2010 kernsaniert und glänzt nun wieder für Sie.</p>
                <p>Die Lage ist ideal: nur ca. 300 m vom Strand und 200 m vom alten Strom entfernt.</p>
                <p>Haus Nina bietet zwei Ferienwohnungen, die sich jeweils über ein gesamtes Stockwerk erstrecken.</p>
                <ul class="list">
                    <li>Ferienwohnung Strandperle (1-6 Personen)</li>
                    <li>Ferienwohnung Strandburg (1-5 Personen)</li>
                </ul>
                <p>Die schicken Wohnungen mit enormer Deckenhöhe sind vollständig neu möbliert und verfügen über Fernseher und komplett eingerichtete Einbauküchen.</p>
                <p>Ganz gleich ob Sie allein, als Pärchen oder mit der ganzen Familie anreisen – wir bieten Ihnen die passende Unterkunft und freuen uns auf Ihren Besuch.</p>
                <p>Ihre Familie Schäfer</p>
            </b-col>
            <b-col cols="12" sm="6">
                <picture>
                    <source media="(min-width: 768px)" :data-srcset="require('~/assets/img/hausnina/Postkarte_gedreht@2x.png?size=405').src + ' 1x,' + require('~/assets/img/hausnina/Postkarte_gedreht@2x.png?size=810').src + ' 2x'"/>
                    <source media="(min-width: 320px)" :data-srcset="require('~/assets/img/hausnina/Postkarte_gedreht@2x.png?size=768').src + ' 1x, ' + require('~/assets/img/hausnina/Postkarte_gedreht@2x.png?resize').src + ' 2x'"/>
                    <img class="lazyload" :src="require('~/assets/img/hausnina/Postkarte_gedreht@2x.png?resize').placeholder" :data-src="require('~/assets/img/hausnina/Postkarte_gedreht@2x.png?size=405').src" alt="Ferienwohnung Haus Nina"/>
                </picture>
            </b-col>
        </b-row>
    </b-container>
</template>

<script>
    export default {
        name: "Willkommen",
    }
</script>

<style scoped lang="scss">
    picture img {
        width: 100%;
    }
</style>